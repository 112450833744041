<template>
  <f7-page name="store-list">
    <f7-navbar back-link="Back" title="店铺">
    </f7-navbar>
    
    <f7-list >
        <f7-list-item  
          v-for="(store, index) in storeList"
          :key="index"
          :title="store.storeName"
          @click="onClickStore(store)"
        >
          <img slot="media" :src="store.storeLogoUrl" width="44" />
        </f7-list-item>
    </f7-list>

  </f7-page>
</template>

<script>
import toast from '../mixins/toast';

export default {
  mixins: [toast],
    data() {
      return {
        storeList:[]
      }
    },
    mounted() {
      this.getStoreList();
    },
    methods: {
      getStoreList() {
        let self= this;
        this.$axios.get('/store').then(function (response) {
          console.log("server response store list -> ", response.data);
          self.storeList=response.data;
        }).catch(function(imError) {
          console.warn('store list error:', imError);
        });
      },
      onClickStore(store) {
        this.$f7router.navigate(
          { 
            path: '/conversation-detail/',
            query: {
              storeId: store.id,
              conversationName: store.storeName,
              conversationLogoUrl: store.storeLogoUrl,
            }
          }
        );
      },
    }

}
</script>