<template>
  <f7-page name="login">
    <!-- Top Navbar -->
    <f7-navbar title="选择要转交的客服">
    </f7-navbar>

    <f7-list>
      <f7-list-item
        v-for="(c, index) in userList"
        @click="select(c)"
        :key="index"
        :title="c.name">
        <img slot="media" :src="c.logoUrl" width="44" />
      </f7-list-item>
    </f7-list>

  </f7-page>
</template>

<script>
import qs from 'qs';
import ChatUtil from 'chat-util/web';
import * as loginService from '../services/login';
import * as chatService from '../services/chatService';
import toast from '../mixins/toast';

export default {
  mixins: [toast],
  data() {
    return {
      action: '', // transfer-service-permission
      storeId: -1,
      fromUserId: null,
      userList: [],
    }
  },
  async created() {
    this.action = this.$f7route.query.action;
    this.storeId = this.$f7route.query.storeId;
    this.fromUserId = this.$f7route.query.fromUserId;
    this.$f7.preloader.show();
    await this.initUserList();
    this.$f7.preloader.hide();
  },
  methods: {
    async initUserList() {
      const { success, data, errorMessage } = await loginService.getUserList();
      if (success) {
        this.$set(this, 'userList', data.filter(_=>(
          _.roleType == 1
          && _.storeId == this.storeId
          && _.id != this.fromUserId
        )));
      }
      else this.toast(errorMessage);
    },
    async select(user = {}) {
      this.$f7.preloader.show();
      if (!user.id) {
        alert("请选择用户");
        return;
      }
      const { success, data, errorMessage } = await chatService.DemoTransferServiceWork(this.fromUserId, user.id);
      if (success) {
        this.toast('转交成功');
        this.$f7router.back();
      }
      else this.toast(errorMessage);
      this.$f7.preloader.hide();
    },
  }
}
</script>
