import qs from 'qs'
import parrotRequest from './parrotRequest'
import {
  getLoginToken,
  setLoginInfo
} from './authority'

export function request () {
  const APIBase = location.origin

  const args = [].slice.call(arguments, 0)
  const url = new URL(args[0], APIBase)

  // 统一追加 Query 参数
  const queryParams = qs.parse(url.search.slice(1))
  //  - 统一追加 LoginToken
  queryParams.loginToken = getLoginToken() || ''

  url.search = `?${qs.stringify(queryParams)}`

  // 发出请求
  args[0] = url.toString()
  return parrotRequest.apply(this, args).then((res) => {
    // 业务错误捕获
    const {
      status
    } = res
    if (status === 401) {
      setLoginInfo({})
      // 登录过期跳转统一处理
    }
    return res
  })
}

export default request
