
<template>
  <f7-page>
    <f7-navbar title="位置" back-link="返回">
    <f7-nav-right v-if="!onlyShow">
      <f7-link @click="sendPoistion">发送</f7-link>
    </f7-nav-right>
    </f7-navbar>
    <img v-if="!onlyShow" src="https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png"
         style="position: absolute; left: 50%; bottom: 47.25%; width: 27px; height: 43px; user-select: none; border: 0px; padding: 0px; margin: 0px; transform: translateX(-50%); pointer-events: none; z-index: 2;">
    <gmap-map
        v-if="readyToShowMap"
        :center="position"
        :zoom="11"
        :draggable="!onlyShow"
        style="width: 100%; height: 100%"
        @center_changed="updateCenterPosition"
      >
       <gmap-marker v-if="onlyShow" :center="position" :position="position" :draggable="!onlyShow" />
    </gmap-map>
  </f7-page>
</template>
<script>
import axios from "axios";
import { gmapApi } from "vue2-google-maps";
import ChatUtil from 'chat-util/web';

export default {
  data() {
    return {
      readyToShowMap: false,
      onlyShow: false,
      position: {
        lat: 0,
        lng: 0,
      },
      resPosition: {
        lat: 0,
        lng: 0,
      }
    };
  },
  computed: {
    google: gmapApi, // 获取官方的OBject 使用官方API的时候可以用
  },
  created() {
    if (this.$f7route.query && this.$f7route.query.lat) {
      this.position.lat = parseFloat(this.$f7route.query.lat);
      this.position.lng = parseFloat(this.$f7route.query.lng);
      this.onlyShow = this.$f7route.query.onlyShow;
    }
  },
  mounted() {
    console.log("mounted position", this.position);
    setTimeout(()=>{
      this.readyToShowMap = true;
    }, 10);
    if (navigator.geolocation && !this.onlyShow) {
      navigator.geolocation.getCurrentPosition(
        //locationSuccess 获取成功的话
        (position) => {
          this.getLatitude = position.coords.latitude;
          this.getLongitude = position.coords.longitude;
          this.position.lat = this.getLatitude;
          this.position.lng = this.getLongitude;
        },
        //locationError  获取失败的话
        (error) => {
          console.log("nav error", error);
          var errorType = [
            "您拒绝共享位置信息",
            "获取不到位置信息",
            "获取位置信息超时"
          ];
          alert(errorType[error.code - 1]);
        }
      );
    }
  },
  methods: {
    sendPoistion() {
      if (!this.onlyShow) {
        ChatUtil.sendMsg({
          lat: this.resPosition.lat,
          lng: this.resPosition.lng,
          msgType: 'POSITION',
          toId: ChatUtil.getCurrentConversationId()
        });
        this.$f7router.back();
      }
    },
    updateCenterPosition(event) {
      if (this.onlyShow) {
        return;
      }
      this.resPosition = {
        lat: event.lat(),
        lng: event.lng(),
      }
    }
  }
};
</script>
<style>
</style>
