<template>
  <f7-page name="login">
    <!-- Top Navbar -->
    <f7-navbar title="请选择登录身份">
    </f7-navbar>

    <f7-block-title>买家身份</f7-block-title>
    <f7-list>
      <f7-list-item
        v-for="(buyer, index) in buyerList"
        @click="login(buyer)"
        :key="index"
        :title="buyer.name">
          <img slot="media" :src="buyer.logoUrl" width="44" />
      </f7-list-item>
      </f7-list>

    <f7-block-title>卖家身份</f7-block-title>
    <f7-list>
      <f7-list-item
        v-for="(c, index) in customerServiceList"
        @click="login(c)"
        :key="index"
        :title="c.name">
        <img slot="media" :src="c.logoUrl" width="44" />
      </f7-list-item>
    </f7-list>
    <f7-block-footer>v{{pkg.version}}</f7-block-footer>
  </f7-page>
</template>

<script>
import pkg from '../../package.json';
import qs from 'qs';
import ChatUtil from 'chat-util/web';
import * as loginService from '../services/login';
import { setLoginInfo } from '../util/authority';
import toast from '../mixins/toast';

export default {
  mixins: [toast],
  data() {
    return {
      pkg,
      userList: [],
    }
  },
  computed: {
    buyerList(){
      return (this.userList || []).filter(_=>_.roleType == 0)
    },
    customerServiceList(){
      return (this.userList || []).filter(_=>_.roleType == 1)
    }
  },
  async created() {
    // 全局监听 IM 连接被踢出登录的情况
    // Demo 代码特别处: 放在这个页面的这个生命周期下，是因为 app.js 里无法取到 this.$f7router，实际项目中可以放在别的合适位置
    ChatUtil.$on("STATUS_CHANGE", this.onLoginExpired);
    this.$f7.preloader.show();
    await this.initUserList();
    this.$f7.preloader.hide();
    // Demo 如果带了快速登录参数，直接登录
    const query = qs.parse(location.search.slice(1));
    if (query.loginId) {
      const userId = this.userList.filter(_=>_.id==query.loginId)[0]
      if (userId) {
        this.login(userId);
      }
    }
  },
  destroyed(){
    ChatUtil.$on("STATUS_CHANGE", this.onLoginExpired);
  },
  methods: {
    async initUserList() {
      const { success, data, errorMessage } = await loginService.getUserList();
      if (success) {
        this.$set(this, 'userList', data);
      }
      else this.toast(errorMessage);
    },
    async login(user = {}) {
      this.$f7.preloader.show();
      if (!user.id) {
        alert("请选择用户");
        return;
      }
      await this.logout(user);
      // 假装登录成功并获取到了完整用户信息（登录信息+用户信息）
      const { success, data, errorMessage } = await loginService.getUserDetail(user.id);
      if (success) {
        const loginInfo = {
          ...data,
          loginId: user.id,
          loginToken: user.loginToken, // 演示用 loginToken，TODO 登录鉴权机制代码
        }
        setLoginInfo(loginInfo)
        // 为该账号建立 IM 连接，不用等待，失败了会自动重连
        this.connectIM(loginInfo);
        // 直接跳到会话列表
        this.jumpConversationList();
        this.$f7.preloader.hide();
        // Demo：标记当前登录 ID，刷新页面后自动登录
        history.replaceState({}, '', `?loginId=${loginInfo.loginId}`)
      }
      else this.toast(errorMessage);
    },
    // ⭐️
    async connectIM(loginInfo) {
      // 传入基础用户信息，建立 IM 连接
      return ChatUtil.connect({
        userInfo: {
          id: loginInfo.loginId,
          roleType: loginInfo.roleType == 0 ? 0 : 1, // 0是买家，1是客服
          logoUrl: loginInfo.logoUrl,
          name: loginInfo.name,
          storeId: loginInfo.storeId,
          // 也支持直接指定用户的 imUserToken
          // imUserToken: 'T6XIvaV8ZZAnYMEFEvhudMPr/rFMbbhWqeGHL+/EJL8EJr0qkwnB0w==@5tpp.sg.rongnav.com;5tpp.sg.rongcfg.com',
        }
      });
    },
    async logout(user) {
      // 退出登录时断开 IM 连接
      await ChatUtil.disconnect()
    },
    jumpConversationList() {
      this.$f7router.navigate({
          path: '/conversation-list/'
        }
      );
    },
    onLoginExpired(event){
      if (event.status == 6) {
        this.toast('您已在别处登录');
        this.$f7router.navigate('/');
      }
    }
  }
}
</script>
