
import NotFoundPage from '../pages/404.vue';
import LoginPage from '../pages/login.vue';
import ConversationDetail from '../pages/conversation-detail.vue';
import ConversationList from '../pages/conversation-list.vue';
import StoreList from '../pages/store-list.vue';
import StoreSettings from '../pages/store-settings.vue';
import GoogleMap from '../pages/google-map.vue';
import SelectService from '../pages/select-service.vue';

var routes = [
  {
    path: '/',
    component: LoginPage,
    keepAlive: true,
  },
  {
    path: '/conversation-detail/',
    component: ConversationDetail,
  },
  {
    path: '/conversation-list/',
    component: ConversationList,
  },
  {
    path: '/store-list/',
    component: StoreList,
  },
  {
    path: '/store-settings/',
    component: StoreSettings,
  },
  {
    path: '/google-map/',
    component: GoogleMap,
  },
  {
    path: '/select-service/',
    component: SelectService,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
