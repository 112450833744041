<template>
  <f7-page name="conversation-list" @page:beforein="beforein">
    <f7-navbar back-link="登录">
      <f7-nav-title>
        <!-- 连接中提示 -->
        <f7-preloader v-if="connStatus == 1" :size="16"></f7-preloader>
        {{currentRoleType == 0 ? "消息通知" : "客服会话"}}
        <!-- 连接错误提示 -->
        <template v-if="connStatus && connStatus != 0 && connStatus != 1">
          (未连接#{{connStatus}})
        </template>
        <!-- 未读消息数 -->
        <f7-badge v-if="unreadCount" class="unread-count" color="red">{{unreadCount}}</f7-badge>
        <div class="subtitle">(当前账号：{{currentName}})</div>
      </f7-nav-title>
      <f7-nav-right>
        <f7-link
          icon-ios="f7:ellipsis"
          icon-aurora="f7:ellipsis"
          icon-md="f7:ellipsis"
          slot="inner-end"
          @click="(currentRoleType==0 ? $refs.buyerActionsGroup : $refs.sellerActionsGroup).open()"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-actions ref="buyerActionsGroup">
      <f7-actions-group>
        <f7-actions-button ><f7-link href="/store-list/">进入店铺</f7-link></f7-actions-button>
        <f7-actions-button @click="clearUnreadCount">清除未读消息数</f7-actions-button>
      </f7-actions-group>
    </f7-actions>

    <f7-actions ref="sellerActionsGroup">
      <f7-actions-group>
        <f7-actions-button @click="clearUnreadCount">清除未读消息数</f7-actions-button>
        <f7-actions-button @click="transferServiceWork">客服身份转交</f7-actions-button>
        <f7-actions-button><f7-link href="/store-settings/">设置</f7-link></f7-actions-button>
      </f7-actions-group>
    </f7-actions>

    <f7-list>
      <f7-list-item
        swipeout
        v-for="conversation in conversationList"
        :key="conversation.id"
        :title="conversation.conversationName"
        :badge="conversation.unreadMsgNum"
        :after="_formatLastMessageTime(conversation.lastMessageClientTime || conversation.lastMessageTime)"
        @click="onClickConversation(conversation)"
        @swipeout:deleted="deleteConversation(conversation)"
      >
        <img slot="media" :src="conversation.conversationLogoUrl" width="44" />
        <span slot="footer" >
          <template v-if="draftMap[conversation.id] && draftMap[conversation.id].trim()">
            <span style="color: red;">[草稿]</span>{{draftMap[conversation.id]}}
          </template>
          <!-- 消息展示优先级：草稿消息 > 普通消息 -->
          <template v-else>
            <!-- 自己发出的消息，展示发送中、发送失败、已读未读等状态，TIPS 类消息不展示这些状态 -->
            <template v-if="isSendByMyself(conversation) && conversation.lastMessageType != 'TIPS'">
              <template v-if="conversation.lastMessageSendFailed">
                [发送失败]
              </template>
              <template v-else-if="conversation.lastMessageIsSending">
                [发送中...]
              </template>
              <template v-else-if="conversation.lastMessageHasRead">
                [已读]
              </template>
              <template v-else>
                [未读]
              </template>
            </template>
            <!-- 消息内容 -->
            <template v-if="['TEXT', 'TIPS'].includes(conversation.lastMessageType)">
              <!-- 撤回消息的提示特别展示 -->
              <template v-if="conversation.lastMessageSubType === 'RECALL'">
                <template v-if="isSendByMyself(conversation)">
                  你撤回了一条消息
                </template>
                <template v-else>
                  对方撤回了一条消息
                </template>
              </template>
              <!-- 普通文本消息和提示消息 -->
              <span v-else>{{conversation.lastMessageContent || ''}}</span>
            </template>
            <template v-else-if="conversation.lastMessageType === 'IMAGE'">
              [图片]
            </template>
            <template v-else-if="conversation.lastMessageType === 'POSITION'">
              [地理位置]
            </template>
            <template v-else-if="conversation.lastMessageType === 'CARD'">
              [卡片消息]
            </template>
            <template v-else>
              [暂不支持展示的消息]
            </template>
          </template>
        </span>
        <f7-swipeout-actions right>
          <f7-swipeout-button delete>Delete</f7-swipeout-button>
        </f7-swipeout-actions>
      </f7-list-item>
    </f7-list>
    <f7-block-footer>v{{pkg.version}}</f7-block-footer>
  </f7-page>
</template>

<script>
import pkg from '../../package.json';
import moment from 'moment';
import ChatUtil from 'chat-util/web';
import { getLoginInfo } from '../util/authority';
import * as chatService from '../services/chatService';
import toast from '../mixins/toast';

const convListModifyAtom = ChatUtil.createAtomPipe();

export default {
  mixins: [toast],
  data() {
    return {
      pkg,
      connStatus: null,
      currentUserId: null,
      currentUserStoreId: null,
      currentName: null,
      currentRoleType: -1,
      conversationList: [],
      draftMap: {},
      unreadCount: 0,
      isFirstTimeInPage: true,
    };
  },
  created(){
    // 初始化连接状态码
    this.connStatus = ChatUtil.getConnectionStatus();
    this.unreadCount = ChatUtil.getUnreadCount();
    ChatUtil.$on('*', this.handleAnyChatEvent);
  },
  beforeDestroy(){
    ChatUtil.$off('*', this.handleAnyChatEvent);
  },
  methods: {
    async beforein() {
      // 更新草稿消息展示
      this.draftMap = { ...(await ChatUtil.getDraftMap()) };
      // 只在首次进入页面时，或者当前登录账号变化时，显示 Loading 并重新加载会话列表，后续都是依靠 CONVERSATION_CHANGE 事件更新会话
      if (this.isFirstTimeInPage || this.currentUserId != ChatUtil.userInfo.id) {
        this.isFirstTimeInPage = false;
        this.$f7.preloader.show();
        // 更新当前连接是客服还是买家，用于展示逻辑
        this.currentUserId = ChatUtil.userInfo.id;
        this.currentUserStoreId = ChatUtil.userInfo.storeId;
        this.currentRoleType = ChatUtil.userInfo.roleType;
        this.currentName = ChatUtil.userInfo.name;
        // 加载会话列表
        const res = await this.getConversationList();
        this.$f7.preloader.hide();
      }
    },
    async getConversationList() {
      return convListModifyAtom.run(async ()=>{
        var res = await chatService.getConversationList();
        if (res.success) {
          // Demo 中简化省略了分页功能
          this.$set(this, 'conversationList', res.data.resultSet)
        }
        else this.toast(res.errorMessage)
        return res;
      });
    },
    onClickConversation(conversation) {
      ChatUtil.setCurrentConversation(conversation);
      this.$f7router.navigate(
        {
          path: '/conversation-detail/',
          query: {
            conversationId: conversation.id,
            conversationName: conversation.conversationName,
            conversationLogoUrl: conversation.conversationLogoUrl,
          }
        }
      );
    },
    async deleteConversation(conversation) {
      convListModifyAtom.run(async ()=>{
        var res = await ChatUtil.deleteConversation(conversation);
        if (res.success) {
          this.$set(this, 'conversationList', [...this.conversationList.filter(_=>_.id != conversation.id)]);
        }
        else this.toast(res.errorMessage);
      })
    },
    async handleAnyChatEvent(eventName, event){
      convListModifyAtom.run(async ()=>{
        // 连接状态变化
        if (eventName == 'STATUS_CHANGE') {
          this.connStatus = event.status;
        }
        // 未读数变化
        else if (eventName == 'UNREAD_COUNT_CHANGE') {
          this.unreadCount = event.unreadCount;
        }
        // 自动基于事件更新会话列表数据
        const res = await ChatUtil.updateConversationListByAnyEvent(this.conversationList, eventName, event);
        if (res.success) {
          // 强制赋值一下是为了绕过「子对象新追加的属性，Vue 无法监听其变化」的问题。
          // React、Redux 则本身如此做法，脏检查在渲染层做
          this.$set(this, 'conversationList', [...res.data]);
        }
      });
    },
    async clearUnreadCount() {
      const {success, errorMessage} = await ChatUtil.clearUnreadCount();
      if (!success) {
        this.toast(res.errorMessage);
      }
    },
    transferServiceWork(){
      this.$f7router.navigate({
        path: '/select-service/',
        query: {
          action: 'transfer-service-permission',
          storeId: getLoginInfo().storeId,
          fromUserId: this.currentUserId
        }
      });
    },
    isSendByMyself(conversation){
      return this.currentRoleType == 0 ? (
        conversation.lastMessageFromId == this.currentUserId
      ) : (
        conversation.lastMessageFromStoreId == this.currentUserStoreId
      )
    },
    _formatLastMessageTime(t){
      if (!t) {
        return '';
      }
      let date = moment(t)
      if (date.isSameOrAfter(new Date(), 'day')) {
        return date.format('HH:mm')
      }
      else {
        return date.format('MM-DD');
      }
    }
  }
};
</script>

<style>
.unread-count {
  position: relative;
  top: -2px;
}
.list .item-after .badge {
  margin-left: 7px;
  background: rgb(255, 81, 81);
  position: relative;
  top: 1px;
}
.list .item-footer {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
