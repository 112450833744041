// Import Vue
import Vue from 'vue';

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.less';

// Import App Component
import App from '../components/app.vue';

import axios from 'axios'
import qs from 'qs'

import global_ from '../components/Global.vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import * as chatService from "../services/chatService";
import ChatUtil from 'chat-util/web';
import LongPress from 'vue-directive-long-press'

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

Vue.prototype.$qs = qs
Vue.prototype.$axios = axios
Vue.prototype.$axios.defaults.baseURL = '/api' // `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL

// 谷歌地图配置
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBqvtM5L5t7CiPzquGwemGCufnRwrU2z5o',
    libraries: 'places',
    region: 'VI', // 这个地区自己定
    language: 'en' // 这个语言自己定
  },
  installComponents: true
});


Vue.prototype.$global_ = global_

// Init App
new Vue({
  el: '#app',
  directives: {
    'long-press': LongPress
  },
  render: (h) => h(App),
  // Framework7 parameters that we pass to <f7-app> component
  framework7: {
    toast: {
      closeTimeout: 2000,
      position: 'center',
    },
    theme: 'ios', // Automatic theme detection
  },
  // App root data
  data() {
    return {
    };
  },
  mounted(){
    // IM 组件初始化
    ChatUtil.init({
      chatService,
      appKey: 'sfci50a7sjqhi',
      getErrorMessageByCode( code ){
        // 如果是多语言，建议多语言词条 key 的格式为 `im_error_code_${code}`，方便远程完善新错误码的文案
        return ({
          '220604': '您太快了，请慢点',
          '300001': '您发送的内容太长了，请短一点',
          '230001': '当前未连接，请检查网络',
          '222406': '您不在该会话中',
          '-1': '网络超时',
        })[code];
      },
      debug: true,
      logLevel: 'log'
    });

    // Web Chrome 浏览器，申请推送消息的权限
    try {
      if (typeof Notification !== 'undefined' && Notification.permission !== "granted") {
          Notification.requestPermission(function (permission) {})
      }
    } catch (error) {
      console.error(error)
    }
  }
});
