<script>
const messageMap = new Map();
const conversationId = "";
const conversationList = [];
const conversationInfoMap = new Map();
const position = {};
const draftMap = {};

export default {
  messageMap,
  conversationId,
  conversationList,
  position,
  conversationInfoMap,
  draftMap
};
</script>
