import request from '../util/request'

export const getIMUserToken = () => request('/api/rongCloudUserTokens', {
  method: 'POST'
});

export const getConversationDetail = (user, toId) => request('/api/conversations/' + toId);

export const getConversationList = (params) => request('/api/conversations', {
  data: params
});

export const getConversationListById = ({idList}) => request('/api/conversations', {
  data: {idList}
});

export const deleteConversation = (conversationId) => request('/api/conversations/' + conversationId, {
  method: 'delete'
});

export const createConversation = (params) => request('/api/conversations', {
  method: 'post',
  data: {
    storeId: params.storeId,
  }
});

export const finishConversation = (conversationId) => request('/api/conversations/' + conversationId, {
  data: {
    endStatus: true
  }
});

export const getMessageList = (params) => request('/api/messages', {
  data: {
    conversationId: params.conversationId,
    lastMessageTime: params.lastMessageTime,
    firstMessageTime: params.firstMessageTime,
    ...params
  }
});

export const recallMessage = (msgUid)=> request('/api/messages/recallMsg', {
  method: 'post',
  data: {
    msgUid
  }
});

export const clearUnreadCount = () => request('/api/conversations/cleanUnreadMsgNum', {
  method: 'post',
});

export const getUserUnreadCount = () => request('/api/users/unreadMsgNum');

export const DemoTransferServiceWork = (fromUserId, toUserId) => request(`/api/conversations/changeCustomerService?from=${fromUserId}&to=${toUserId}`);

export const getTimestampFromRequestRes = (res) => {
  return new Date(res.response.headers.get('date')).getTime();
}
