<template>
  <f7-page :ptr="messageListHasMore" @ptr:refresh="loadMoreMessageList">
    <f7-navbar>
      <f7-nav-left :back-link="unreadCountExceptCurrent ? ' ':'返回'">
        <!-- 其他会话的未读消息 -->
        <f7-badge v-if="unreadCountExceptCurrent" color="gray">
          {{unreadCountExceptCurrent}}
        </f7-badge>
      </f7-nav-left>
      <f7-nav-title>
        {{conversationName}}
        <div class="subtitle">
          {{targetIsInputting? '对方正在输入...' : (targetLastMsgSourceType || '')}}
        </div>
      </f7-nav-title>
    </f7-navbar>

    <!-- 消息列表容器 -->
    <f7-messages ref="messages" :scroll-messages="false" :scroll-messages-on-edge="false">
      <!-- 滚动到底提示 -->
      <div class="top-loading-tip">
        <f7-messages-title v-if="!messageListHasMore && messageListForRender.length > 0">
          没有更早之前的消息
        </f7-messages-title>
      </div>

      <!-- 消息列表 -->
      <template v-for="msg in messageListForRender">
        <!-- 时间展示逻辑 （优先用客户端时间是为了避免渲染抖动） -->
        <f7-messages-title v-if="msg.showTime" :key="msg.msgClientSendTime || msg.msgSendTime">
          {{_formatMsgTime(msg.msgClientSendTime || msg.msgSendTime)}}
        </f7-messages-title>
        <!-- 消息内容 -->
        <!-- TIPS 提示消息 -->
        <f7-messages-title v-if="msg.msgType == 'TIPS'" :key="_getMsgKey(msg)">
          <!-- 针对撤回类提示消息单独处理 -->
          <template v-if="msg.msgSubType == 'RECALL'">
            <template v-if="isSendByMyself(msg)">
              <span v-if="msg.recalledMsg && msg.recalledMsg.msgType == 'TEXT' && msg.recalledMsg.text">
                你撤回了一条消息，<a href="javascript:;" @click="refillInputWithRecalledMsg(msg.recalledMsg.text)">点击重新编辑</a>
              </span>
              <span v-else>
                你撤回了一条消息
              </span>
            </template>
            <template v-else>
              对方撤回了一条消息
            </template>
          </template>
          <!-- 其他 TIPS 内容 -->
          <span v-else v-html="(msg.text || '').replace(/\n/g, '<br>').trim()"></span>
        </f7-messages-title>
        <!-- 文本、图片 等消息 -->
        <f7-message
          v-else
          :class="{
            'message-has-footer': isSendByMyself(msg)
          }"
          :key="_getMsgKey(msg)"
          :type="(isSendByMyself(msg) ? 'sent' : 'received')"
          :avatar="msg.senderLogoUrl"
          :last="true"
          :tail="true"
          v-long-press="300"
          @long-press-start="handleLongPressMsg(msg)"
        >
          <!-- 消息发送状态 -->
          <div slot="end" class="message-status-box">
            <!-- 发送失败 -->
            <div v-if="msg.sendFailed" @click="resendFailedMsg(msg)">
              <f7-icon material="error" color="red"></f7-icon>
            </div>
            <!-- 发送中 -->
            <div v-else-if="msg.isSending">
              <f7-preloader></f7-preloader>
            </div>
          </div>
          <!-- 各种消息类型的展示 -->
          <!-- 文本消息 -->
          <template v-if="msg.msgType == 'TEXT'">
            <pre slot="text">{{(msg.text || '').trim()}}</pre>
          </template>
          <!-- 图片消息 -->
          <template v-else-if="msg.msgType == 'IMAGE'">
            <img slot="image" :src="msg.mediaUrl && msg.mediaUrl || msg._tempDisplay"
              :style="{
                width: msg.displayWidth ? (msg.displayWidth +'px') : undefined,
                height: msg.displayHeight ? (msg.displayHeight +'px') : undefined,
              }"
            />
            <div v-if="msg.isSending" slot="bubble-end" class="upload-progress">{{parseInt(msg._percent)||0}}%</div>
          </template>
          <!-- 定位卡片 -->
          <template v-else-if="msg.msgType == 'POSITION'">
            <div slot="text" v-if="msg.msgType == 'POSITION'" @click="viewMapMsgDetail(msg)">
              <img src="https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png" style="width: 13.5px; height: 21.5px;">
              【定位信息】
            </div>
          </template>
          <!-- 撤回 -->
          <template v-else>
            <span>暂不支持的消息类型</span>
          </template>
          <pre slot="footer" v-html="_renderMsgFooterHTML(msg)" />
        </f7-message>
      </template>
      <!-- 滚动不在底部时，显示未读消息计数提示 -->
      <div
        class="unread-bellow-tipbox"
        v-if="unreadTargetMsgCount && showUnreadBellowTipbox"
        @click="isScrollHitBottom = true"
      >
        <f7-icon material="keyboard_arrow_down"></f7-icon>
        {{unreadTargetMsgCount}} 条信息未读
      </div>
    </f7-messages>

    <!-- 发消息栏 -->
    <f7-messagebar
      placeholder="Message"
      ref="messagebar"
      @input="handleTextInput"
      @blur="handleInputBlur"
    >
      <!-- 发送图片按钮 -->
      <f7-link
        icon-ios="f7:camera_fill"
        icon-aurora="f7:camera_fill"
        icon-md="f7:camera_fill"
        slot="inner-start"
        href="javascript:;"
        style="position: relative; overflow: hidden;"
      >
        <input
          type="file"
          style="position: absolute; left: 0; top: 0; right: 0; bottom: 0; opacity: 0; z-index: 99;"
          multiple
          @change="uploadAndSendImageMsg"
        />
      </f7-link>
      <!-- 发送地理位置按钮 -->
      <f7-link
        icon-ios="f7:placemark_fill"
        icon-aurora="f7:placemark_fill"
        icon-md="f7:placemark_fill"
        slot="inner-start"
        @click="goSendMapMsg"
      ></f7-link>
      <!-- 消息发送按钮 -->
      <f7-link
        icon-ios="f7:arrow_up_circle_fill"
        icon-aurora="f7:arrow_up_circle_fill"
        icon-md="material:send"
        slot="inner-end"
        @click="sendTextMsg"
      ></f7-link>
    </f7-messagebar>

    <!-- 消息长按出现 ActionSheet -->
    <f7-actions ref="msgActionsGroup">
      <f7-actions-group>
        <f7-actions-button @click="recallMsg">撤回</f7-actions-button>
      </f7-actions-group>
    </f7-actions>
  </f7-page>
</template>
<script>
import Vue from "Vue";
import moment from "moment";
import OssClient from "../static/js/OssClient.js";
import ChatUtil from 'chat-util/web';
import { getLoginInfo } from '../util/authority';
import * as chatService from "../services/chatService";
import * as ossService from "../services/oss";
import LongPress from 'vue-directive-long-press';
import toast from '../mixins/toast';

const msgListModifyAtom = ChatUtil.createAtomPipe();
const conversationReadThrottler = ChatUtil.createThrottler();

export default {
  mixins: [toast],
  directives: {
    'long-press': LongPress
  },
  data() {
    return {
      // 当前用户识别，用于渲染
      currentUserId: null,
      currentRoleType: -1,
      currentUserStoreId: null, // 当前客服所在店铺 ID
      // 当前会话信息
      conversationId: '',
      conversationName: '',
      conversationLogoUrl: '',
      // 对方是否输入中
      targetIsInputting: false,
      // 消息列表
      messageList: [],
      messageListHasMore: true,
      // 未读消息滚动提示相关
      isScrollHitBottom: true, // 当前滚动条是否已滚动到底部，同时也被作为「新消息来了之后自动滚动到底部」效果的开关
      showUnreadBellowTipbox: false,
      // 其他会话的未读消息数展示
      unreadCountExceptCurrent: 0,
      // 其他
      _selectedMsg: null, // 长按消息操作时临时使用
    };
  },
  computed: {
    // 追加渲染用的额外信息
    messageListForRender(){
      const messageList = [...this.messageList];
      // 追加时间展示标记 msg.showTime
      ChatUtil.showTimeMsgSlippedIn(messageList);
      messageList.forEach((msg, index)=>{
        // 追加发送者 Logo 等信息
        msg.senderLogoUrl = this.isSendByMyself(msg) ? ChatUtil.userInfo.logoUrl : this.conversationLogoUrl;
        // 给媒体消息计算宽高
        if (msg.extMsgContent && msg.extMsgContent.width && msg.extMsgContent.height) {
          const { width, height } = this._getMediaThumbilDisplaySize(msg.extMsgContent);
          msg.displayWidth = width;
          msg.displayHeight = height;
        }
      });
      return messageList;
    },
    // 对方发来的消息的未读总数
    unreadTargetMsgCount(){
      return this.messageList.filter(msg=> !this.isSendByMyself(msg) && msg.msgType !== 'TIPS' && !msg.hasRead).length;
    },
    targetLastMsgSourceType(){
      return (this.messageList.filter(msg=> !this.isSendByMyself(msg)).reverse()[0] || {}).sourceType;
    }
  },
  async mounted() {
    let res;
    this.currentUserId = ChatUtil.userInfo.id;
    this.currentRoleType = ChatUtil.userInfo.roleType;
    this.currentUserStoreId = ChatUtil.userInfo.storeId;
    this.unreadCountExceptCurrent = ChatUtil.getUnreadCountExceptCurrent();
    // 接收路由入参
    this.conversationId = this.$f7route.query.conversationId;
    this.conversationName = this.$f7route.query.conversationName;
    this.conversationLogoUrl = this.$f7route.query.conversationLogoUrl;
    const targetStoreId = this.$f7route.query.storeId; // 进店创建会话时用

    // Demo 获取输入框节点对象
    this.$f7ready(() => {
      this.messagebar = this.$refs.messagebar.f7Messagebar;
      this.messages = this.$refs.messages.f7Messages;
      // 监听滚动状态
      this.messages.$pageContentEl.scroll(this.handlePageScroll);
      // 恢复输入框草稿内容
      var draft = ChatUtil.getDraft(this.conversationId);
      if (draft) {
        this.messagebar.setValue(draft);
      }
    });

    // 进入会话、创建会话后，为会话组件更新当前会话 ID
    if (!this.conversationId) {
      res = await chatService.createConversation({
        storeId: targetStoreId
      });
      if (res.success) {
        this.conversationId = res.data.id;
        // 获得会话后，设置当前会话
        ChatUtil.setCurrentConversation(res.data);
      } else {
        // 开启会话失败的话，得后退离开页面，避免使用错误数据继续走后续流程
        return this.toast({
          text: res.errorMessage,
          on: {
            close: function() {
              this.$f7router.back();
            }
          }
        });
      }
    }
    // 监听消息相关事件
    ChatUtil.$on('*', this.handleAnyChatEvent);
    // 加载历史消息列表
    res = await this.refreshMessageList();
    if (res.success) {
      // 渲染完成后，滚动到底部
      this.scrollToBottom();
      // 见到消息内容后，认为次会话中的所有消息已读
      // console.error('见到消息内容后，认为次会话中的所有消息已读');
      this.readCurrentConversation();
    }
    // 监听 shift+回车 换行输入事件
    window.addEventListener('keypress', this.handleShiftEnterKeyPress, true);
  },
  beforeDestroy(){
    ChatUtil.$off('*', this.handleAnyChatEvent);
    ChatUtil.removeCurrentConversation();
    this.messages.$pageContentEl.off('scroll', this.handlePageScroll);
    window.removeEventListener('keypress',this.handleShiftEnterKeyPress);
  },
  methods: {
    async refreshMessageList(){
      this.$set(this, 'messageList', []);
      this.messageListHasMore = true;
      return this.loadMoreMessageList();
    },
    // 上翻加载更多消息
    // Demo: pullToRefreshDone 是 Framework7 提供的停止下拉刷新状态的回调函数
    async loadMoreMessageList(pullToRefreshDone = ()=>{}) {
      if (this._messageListFetching || !this.messageListHasMore) {
        pullToRefreshDone();
        return false;
      }
      this._messageListFetching = true;
      return msgListModifyAtom.run(async ()=>{
        const res = await ChatUtil.getMsgList(this.conversationId, ChatUtil.getFirstSendTimeFromMsgList(this.messageList));
        if (res.success) {
          var messageList = res.data.resultSet || [];
          this.messageListHasMore = messageList.length > 0;
          // 使用工具方法帮忙合并消息列表，直接实现顺序控制、去重（重复项优先保留更新的那条）
          messageList = ChatUtil.concatMsgList(messageList, this.messageList);
          // 渲染前先记录滚动位置
          const scrollContentHeightBefore = this.messages.$pageContentEl[0].scrollHeight;
          this.$set(this, 'messageList', messageList);
          // 如果在往上翻看消息，那么渲染后需要修正滚动位置，确保渲染前后看到的消息位置不发生变化
          if (!this.isScrollHitBottom) {
            Vue.nextTick(()=>{
              const scrollContentHeightAfter = this.messages.$pageContentEl[0].scrollHeight;
              const scrollTopAfter = this.messages.$pageContentEl[0].scrollTop;
              this.messages.$pageContentEl.scrollTop(scrollTopAfter + (scrollContentHeightAfter - scrollContentHeightBefore));
            });
          }
        }
        else this.toast(res.errorMessage)
        this._messageListFetching = false;
        pullToRefreshDone();
        return res;
      });
    },
    async finishConversation() {
      var res = await chatService.finishConversation(this.conversationId);
      if (res.success) {
        this.toast('会话已结束');
        this.$f7router.back();
      }
      else this.toast(res.errorMessage)
    },
    // 监听所有会话事件，更新消息列表
    async handleAnyChatEvent(eventName, event){
      // 忽略不属于当前会话的事件（有明确 toId 指向的）
      if ( event.toId && event.toId != this.conversationId ) {
        return ;
      }
      // 自动基于事件更新消息列表数据
      msgListModifyAtom.run(async ()=>{
        const res = await ChatUtil.updateMsgListByAnyEvent(this.messageList, eventName, event);
        if (res.success) {
          this.$set(this, 'messageList', [...res.data]);
        }
      });
      // 对其他事件的额外处理
      if (eventName == 'MESSAGE:NEW') {
        // 新消息收到，展示后，如果当前滚动条处在底部，说明看见了新消息，直接标为已读
        if (this.isScrollHitBottom) {
          // 滚动到底
          try {
            clearTimeout(this._scrollToEdgeWhenHitBottomTmo)
            this._scrollToEdgeWhenHitBottomTmo = setTimeout(()=>{
              this.scrollToBottom();
            }, this.isSendByMyself(event.msg) ? 10 : 150);
          } catch (e) {
            // 不能干扰正常功能，滚动特性可降级
            console.error(e);
          }
          this.readCurrentConversation();
        }
      }
      else if (eventName == 'MESSAGE:UPDATE') {
        // 如果自己账号的消息发送失败，还需要弹出报错提示（真的只看自己账号）
        if (event.fromId == this.currentUserId && event.change && event.change.errorMessage) {
          this.$f7.toast.create({
            text: event.change.errorMessage,
            closeTimeout: 2000,
            position: 'center',
          }).open();
        }
      }
      // 监听未读消息数变化，更新展示
      else if (eventName == 'UNREAD_COUNT_CHANGE') {
        this.unreadCountExceptCurrent = event.unreadCountExceptCurrent;
      }
      // 监听对方输入状态变化，更新展示
      else if (eventName == 'CONVERSATION:INPUTTING_CHANGE') {
        this.targetIsInputting = event.isInputting;
      }
    },
    handleTextInput(e){
      const text = this.messagebar.getValue();
      // 设为输入中状态
      ChatUtil.sendInputtingHeartBeat( true );
      // 更新草稿缓存
      ChatUtil.setDraft(text);
    },
    handleInputBlur() {
      ChatUtil.sendInputtingHeartBeat( false );
    },
    handlePageScroll(){
      clearTimeout(this._handlePageScrollTmo);
      this._handlePageScrollTmo = setTimeout(()=>{
        const el = this.messages.$pageContentEl[0];
        const scrollDistanceToBottom = el.scrollHeight - el.scrollTop - el.offsetHeight;
        // 更新 isScrollHitBottom 滚动是否贴底
        if (scrollDistanceToBottom < 50) {
          if (!this.isScrollHitBottom) {
            // 滚动贴底了
            this.isScrollHitBottom = true;
            // 将期间新来的消息标为已读
            // console.error('滚动贴底了，将期间新来的消息标为已读');
            this.readCurrentConversation();
          }
        }
        else {
          if (this.isScrollHitBottom) {
            // 滚动离底了
            this.isScrollHitBottom = false;
          }
        }
        // 离底部一定距离后，可以显示底部未读消息提示框
        if (scrollDistanceToBottom > 200) {
          this.showUnreadBellowTipbox = true;
        }
        else {
          this.showUnreadBellowTipbox = false;
        }
      // 在底部小距离时，增加敏感度，避免一进页面就连续滚动时出现问题
      }, this.isScrollHitBottom ? 0 : 100);
    },
    handleLongPressMsg(msg){
      // 不显示撤回按钮的情况：
      // 1. 发送中、发送失败的消息不能撤回
      // 2. 距离消息发出已超过 2分钟，不能撤回
      if (!msg.isSending && !msg.sendFailed && Date.now() - msg.msgSendTime < 60000*2) {
        // Demo 对方的消息长按不弹出菜单，因为目前菜单里只有一个撤回功能。顺便先绕过一个对方消息的长按触发区域过大的问题。
        if (this.isSendByMyself(msg)) {
          this._selectedMsg = msg;
          this.$refs.msgActionsGroup.open();
        }
      }
    },
    async sendTextMsg() {
      // 取消输入中状态
      ChatUtil.sendInputtingHeartBeat( false );
      const text = this.messagebar.getValue().trim();
      if (text.length == 0) {
        return;
      }
      const res = await this.sendMsg({
        toId: this.conversationId,
        msgType: 'TEXT',
        text,
      });
      if (res.success) {
        // 发送消息后清空输入框
        this.messagebar.clear();
        // 删除草稿
        ChatUtil.deleteDraft(this.conversationId);
      }
      else if (res.errorMessage) {
        this.toast(res.errorMessage);
      }
    },
    async uploadAndSendImageMsg(e) {
      const files = e.srcElement.files;
      files.forEach(async (file)=>{
        const base64Url = await this._readFileToBase64(file);
        const size = await this._getWidthHeightOfImage(base64Url);
        this.sendMsg({
          msgType: 'IMAGE',
          toId: this.conversationId,
          _tempDisplay: base64Url,
          extMsgContent: {
            width: size.width,
            height: size.height,
          },
          progress: async (resolve, reject, updateMsg) => {
            // 获取oss配置
            const res = await ossService.getOssInfo();
            if (res.success) {
              // console.log("await oss info -> ", res.data);
              var ossConfig = {
                region: res.data.ossRegion, //oss-cn-beijing-internal.aliyuncs.com
                accessKeyId: res.data.accessKeyId,
                accessKeySecret: res.data.accessKeySecret,
                stsToken: res.data.securityToken,
                bucket: res.data.ossBucket
              };
              // console.log("final oss config", ossConfig);
              const res2 = await this._handleFileUploadRequest( ossConfig, file, (percent)=>{
                updateMsg((msg)=>{
                  msg._percent = percent;
                  return msg;
                });
              });
              if (res2.success) {
                // console.log('upload success', res2);
                const mediaUrl = res2.data.requestUrls[0].replace(/\?uploadId.*$/, '')
                resolve(mediaUrl);
              }
              else {
                reject(res2.errorInfo);
              }
            }
            else this.toast(res.errorMessage);
          },
        });
      });
    },
    sendMsg(msg) {
      // Demo 依赖 F7 的特性触发滚动到底部动作，同时也更新了「是否滚动到底部」标记
      this.isScrollHitBottom = true;
      return ChatUtil.sendMsg({
        ...msg,
        sourceType: 'web'
      });
    },
    // 目前只有 text 类型消息支持重发
    resendFailedMsg(msg){
      if (msg.msgType === 'TEXT') {
        // 移除将要重发的消息
        ChatUtil.deleteMsgFromClient(msg);
        this.sendMsg(msg);
      }
    },
    async recallMsg(msg){
      this.$f7.preloader.show();
      const { success, data, errorMessage } = await ChatUtil.recallMsg(this._selectedMsg);
      this.$f7.preloader.hide();
      if (!success) this.toast(errorMessage)
    },
    readCurrentConversation(){
      // 事先缓存，避免420ms后切换到了别的会话
      const cov = ChatUtil.getCurrentConversation();
      conversationReadThrottler.run(()=>{
        ChatUtil.readConversation(cov);
      }, 420); // 300ms 的滚动动画（Framework 7自带） + 100ms的滚动节流延时 + 20ms buffer
    },
    refillInputWithRecalledMsg( text = '' ){
      this.messagebar.setValue( text );
      this.messagebar.focus();
      // 使用已撤回消息重新编辑，需要同时更新草稿消息
      ChatUtil.setDraft(text);
    },
    goSendMapMsg(position) {
      this.$f7router.navigate({
        path: '/google-map/'
      });
    },
    viewMapMsgDetail(msg) {
      if (msg && msg.msgType == 'POSITION') {
        this.$f7router.navigate({
          path: '/google-map/',
          query: {
            lat: msg.lat,
            lng: msg.lng,
            onlyShow: true,
          }
        });
      }
    },
    isSendByMyself(msg){
      return this.currentRoleType == 0 ? (
        msg.fromId == this.currentUserId
      ) : (
        msg.fromStoreId == this.currentUserStoreId
      )
    },
    scrollToBottom(){
      this.messages.scroll(150, this.messages.$pageContentEl[0].scrollHeight);
      // 确保「是否滚动到底」标记不因滚动过程而变为 false，滚动前滚动后都加上 this.isScrollHitBottom = true;
      this.isScrollHitBottom = true;
      setTimeout(()=>{
        this.isScrollHitBottom = true;
      }, 150);
    },
    handleShiftEnterKeyPress(e) {
      if (e.key === 'Enter' || e.code === 'Enter') {
        // shift+回车，或者 ctrl+回车，不发送消息，而是正常换行输入
        if (e.shiftKey || e.ctrlKey) {
          return false;
        }
        // 回车直接发送消息
        else {
          e.preventDefault();
          this.sendTextMsg();
        }
      }
    },
    _renderMsgFooterHTML(msg){
      const footerTexts = [];
      const isSendByMyself = this.isSendByMyself(msg);
      if (this.isSendByMyself(msg) && !msg.isSending && !msg.sendFailed) {
        footerTexts.push(msg.hasRead ? '已读':'未读');
      }
      if (ChatUtil.debug && msg.log) {
        footerTexts.push((
          msg.log.map((_, i)=>{
            const clientT = [`${i == 0 ? 0 : (_.clientTime - msg.log[i-1].serverTime)}ms`, `${_.clientTime - msg.log[0].clientTime}ms`, `${_.lifeCycle} CT: ${moment(_.clientTime).format('DD/MM HH:mm:ss.SSS')}`];
            const serverT = [`${i == 0 ? 0 : (_.serverTime - _.clientTime)}ms`, `${_.serverTime - msg.log[0].clientTime}ms`, `${_.lifeCycle} ST: ${moment(_.serverTime).format('DD/MM HH:mm:ss.SSS')}`];
            return ([
              isSendByMyself ? clientT.join(' · ') : clientT.reverse().join(' · '),
              isSendByMyself ? serverT.join(' · ') : serverT.reverse().join(' · '),
            ].join('<br/>'))
          })
        ).join('<br/>'));
      }
      return footerTexts.join('<br/>') || ' ';
    },
    _handleFileUploadRequest(ossConfig, file, progressCallback) {
      //上传OSS
      let vm = this;
      vm.disabled = true;
      const client = OssClient(ossConfig);
      //随机命名
      var filename = ossService.getRandomFileName(20) +  "." + file.name.split(".").pop().toLowerCase();
      // 分片上传文件
      return new Promise((resolve) => {
        client.multipartUpload(filename, file, {
          progress: async function(p) {
            let e = {};
            e.percent = p * 100;
            progressCallback(e.percent)
          }
        }).then((res) => {
          resolve({
            success: true,
            data: res.res
          });
        }).catch((err)=>{
          resolve({
            success: false,
            errorInfo: err
          });
        });
      });
    },
    _formatMsgTime(t){
      let date = moment(t)
      if (date.isSameOrAfter(new Date(), 'day')) {
        return date.format('HH:mm')
      }
      else {
        if (date.isSameOrAfter(new Date(), 'year')) {
          return date.format('MM-DD HH:mm');
        }
        else {
          return date.format('YYYY-MM-DD HH:mm');
        }
      }
    },
    _readFileToBase64(file){
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    _getWidthHeightOfImage(src){
      return new Promise((resolve)=>{
        const img = new Image()
        img.onload = ()=>{
          resolve({
            width: img.width,
            height: img.height,
          })
        }
        img.src = src
      });
    },
    // Demo 中为了避免消息被更新时发生动画抖动而做的处理，真实项目中不一定需要
    _getMsgKey(msg){
      return msg.msgTempUid || msg.msgUid;
    },
    // 图片缩略图尺寸计算逻辑
    _getMediaThumbilDisplaySize({width = 0, height = 0}){
      const maxWidth = 200;
      const maxHeight = 300;
      let finalWidth, finalHeight;
      // 没有宽高传入时的展示尺寸
      if (!width || !height) {
        return {
          width: maxWidth,
          height: maxWidth, // 取一个小的正方形框展示
        }
      }
      // 按最大宽度修正宽高
      if (width > maxWidth) {
        finalWidth = maxWidth;
      }
      finalHeight = finalWidth / width * height;
      // 按最大高度修正宽高
      if (finalHeight > maxHeight) {
        finalHeight = maxHeight;
        finalWidth = finalHeight / height * width;
      }
      return {
        width: finalWidth,
        height: finalHeight,
      }
    },
  }
};
</script>

<style scoped>
.page {
  background: #fff;
}
.top-loading-tip {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.message-content .message-image img {
  object-fit: contain;
}
.message-has-footer {
  margin-bottom: 20px;
}
.message-tail {
  padding-bottom: 20px;
}
.message-status-box {
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
}
.message-bubble .upload-progress {
  position: absolute;
  left: 0; right: 0; top: 0; bottom: 0;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.unread-bellow-tipbox {
  position: fixed;
  right: 0;
  bottom: 70px;
  padding: 5px 15px 5px 10px;
  z-index: 9;
  background: #fff;
  box-shadow: 1px 1px 5px #ccc;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  color: #333;
}
</style>

<style>
.message-bubble {
  position: relative;
}
.message-footer {
  /* 固定高度防抖动 */
  min-height: 12px !important;
}
.message-text pre {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}
</style>
