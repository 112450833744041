export default {
  methods: {
    toast(args) {
      if (typeof args == 'string') {
        args = {
          text: args
        }
      }
      this.$f7.toast.create({
        closeTimeout: 2000,
        position: 'center',
        ...args
      }).open();
    }
  }
};