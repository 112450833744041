import qs from 'qs'
// import fetch from 'node-fetch'
import fetch from 'isomorphic-fetch'

// /**
//  * Requests a URL, returning a promise.
//  *
//  * @param  {string} url       The URL we want to request
//  * @param  {object} [options] The options we want to pass to "fetch"
//  * @return {object}           An object containing either "data" or "err"
//  */
//
// return {
//   success: boolean,
//   data: core data json,
//   errorType: enums, // TODO network_error, ...
//   errorMessage: string,
//   errorItems: string,
//   errorCode: number,
//   errorDebugInfo: number,
//   status: number,
// }

export default function parrotRequest(url, options = {}) {

  const defaultOptions = {
    method: 'GET',
  };
  const newOptions = { ...defaultOptions, ...options };
  newOptions.method = (newOptions.method || '').toUpperCase();
  // body 别名 data
  if ( newOptions.data ) {
    newOptions.body = newOptions.data;
    delete newOptions.data;
  }
  // 剔除 undefined 的参数
  if ( newOptions.body ) {
    for ( let key in newOptions.body ) {
      if ( newOptions.body.hasOwnProperty(key) ) {
        if ( newOptions.body[key] === undefined ) {
          delete newOptions.body[key];
        }
      }
    }
  }
  if (newOptions.method === 'POST' || newOptions.method === 'PUT' || newOptions.method === 'PATCH') {
    newOptions.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    ...newOptions.headers,
    };
    newOptions.body = JSON.stringify(newOptions.body);
  }
  else if ( newOptions.body ) {
    url += (url.indexOf('?') == -1 ? '?':'&') + qs.stringify(newOptions.body);
    delete newOptions.body;
  }

  if ( url.indexOf('http') != 0 ) {
    url = location.origin +(url[0]==='/'?'':'/')+ url;
  }

  if ( options.debug ) {
    console.log(`[Request] ${newOptions.method}: ${url}`);
    if ( newOptions.body ) {
      console.log(`[ - Data] ${newOptions.body}`);
    }
  }

  return new Promise(function(resolve){
    let status, rawResponse;
    fetch(url, newOptions).then(function(response){
      status = response.status;
      rawResponse = response;
      if ( newOptions.json === false ) return response.text();
      if (status === 204) {
        return response.text();
      }
      return response.json();
    }, function(e){
      resolve({
        success: false,
        data: null,
        errorType: 'network_error',
        errorMessage: null,
        errorItems: null,
        errorCode: null,
        errorDebugInfo: e,
        status: status,
        response: rawResponse
      });
    })
    .then(function(res){
      // TODO: none 2xx Fail catch.
      if ( (!res && status != 204) || res.errorCode ) {
        resolve({
          success: false,
          data: null,
          errorType: res ? 'biz_error':'no_content',
          errorMessage: res && res.errorMessage,
          errorItems: res && res.errorItems,
          errorCode: res && res.errorCode,
          errorDebugInfo: res && res.errorDebugInfo,
          status: status,
          response: rawResponse
        });
      }
      else {
        resolve({
          success: true,
          data: res,
          errorType: null,
          errorMessage: '',
          errorItems: null,
          errorCode: null,
          errorDebugInfo: null,
          status: status,
          response: rawResponse
        })
      }
    })
    .catch(e=>{
      resolve({
        success: false,
        data: null,
        errorType: 'json_parse_error',
        errorMessage: '',
        errorItems: null,
        errorCode: null,
        errorDebugInfo: e,
        status: status,
        response: rawResponse
      })
    });
  });
}
