<template>
  <f7-page name="store-settings">
    <f7-navbar back-link="Back" title="接入方式">
    </f7-navbar>
    
    <f7-block-title>接入方式</f7-block-title>
    <f7-list>
      <f7-list-item radio title="手动接入" name="demo-radio"></f7-list-item>
      <f7-list-item radio title="自动接入" name="demo-radio"></f7-list-item>
    </f7-list>

  </f7-page>
</template>

<script>
export default {
    
    methods: {
    }

}
</script>