// use localStorage to store the authority info, which might be sent from server in actual project.
import qs from 'qs'

const loginInfoStoreKey = `im-demo-web-authority`
const GuidStoreKey = `im-demo-web-uuid`
let loginInfoTmp

export function getLoginToken () {
  return (getLoginInfo() || {}).loginToken
}

export function getLoginId () {
  return (getLoginInfo() || {}).loginId
}

export function getLoginInfo () {
  if (loginInfoTmp) return loginInfoTmp
  let ret
  if (typeof localStorage !== 'undefined') {
    try {
      ret = JSON.parse(localStorage.getItem(loginInfoStoreKey))
    } catch (error) {
      console.warn(error)
    }
  }
  loginInfoTmp = ret
  return ret
}

export function setLoginInfo (loginInfo) {
  loginInfoTmp = loginInfo
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(loginInfoStoreKey, JSON.stringify(loginInfo))
  }
}